.mobile-nav-btn-cont{
     position: absolute;
     width: 45px;
     height: 35px;
     right: 10%;
     top: 50%;
     transform: translate(-15%, -50%);
     background: transparent;
     z-index: 100;
   }
   .one{
    position: absolute;
    top: 0;
    width: 100%;
    height: 8px;
    background: black;
    border-radius: 25px;
    transition: 0.5s ease-in-out;
   }
     .two{
     position: absolute;
     width: 100%;
     height: 8px;
     top: 50%;
     background: black;
     transform: translateY(-50%);
     border-radius: 25px;
     transition: 0.5s ease-in-out;
   }
     .three{
     position: absolute;
     width: 100%;
     height: 8px;
     top: 100%;
     background: black;
     transform: translateY(-100%);
     border-radius: 25px;
     transition: 0.5s ease-in-out;
   }

   /* Navbar mobile small and less screen size collapse menu */

  .mobile-collapse-menu{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: white;
    z-index: 3;
    left: 100%;
    transition: 0.7s cubic-bezier(.8, 0, .2, 1);
  }

  .mobile-collapse-menu .nav-btns-cont{
    position: relative;
    display: inline-block;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
    background: transparent;
  }

  .mobile-collapse-menu .nav-btns-cont ul{
    margin: 0;
    padding: 0;
    list-style: none;
    background: transparent;
  }

  .mobile-collapse-menu .nav-btns-cont ul li{
    background: transparent;
  }

  .mobile-collapse-menu .nav-btns-cont ul li a{
    font-size: 60px;
    background: transparent;
    font-family: 'Poppins', sans-serif;
    transition: 500ms;
  }

  .mobile-collapse-menu .nav-btns-cont ul li a:hover{
    text-decoration: none;
    color: #000;
  }

  .mobile-collapse-menu .social-media-cont-mobile{
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -90%);
    
  }

  .mobile-collapse-menu .social-media-cont-mobile i{
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    background: transparent;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid grey;
    transition: 500ms;
    cursor: pointer;
  }

  .mobile-collapse-menu .social-media-cont-mobile i:hover{
    background: coral;
    color: white;
    transition: 500ms;
    border: 1px solid coral;
  }

  .mobile-collapse-menu .social-media-cont-mobile i:nth-child(4){
    padding: 8px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 18px;
  }
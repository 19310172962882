*{
  background:  #f8f9fa;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body{
  width: 100%;
  margin: 0;
}

/* Color Change theme color-theme="Change Color" */
/* #f89d36, #2d84fb, #67c56e, #dd2020, #662e9b */
[color-theme="orange"]{
color: #f89d36;
}
[color-theme="lightblue"]{
  color: #2d84fb;
  }
[color-theme="lightgreen"]{
  color: #67c56e;
  }
[color-theme="red"]{
  color: #dd2020;
  }
[color-theme="purple"]{
  color: #662e9b;
  }
  [color-theme="black"]{
    color: #000;
    }

/* Nav A Color change on Hover */

[nav-color-change="orange"]{
  color: #f89d36;
  }
  [nav-color-change="lightblue"]{
    color: #2d84fb;
    }
  [nav-color-change="lightgreen"]{
    color: #67c56e;
    }
  [nav-color-change="red"]{
    color: #dd2020;
    }
  [nav-color-change="purple"]{
    color: #662e9b;
    }
    [nav-color-change="black"]{
      color: #000;
      }

/* background - Color Change */

[bg-color-theme="orange"]{
  background: #f89d36;
  }
  [bg-color-theme="lightblue"]{
    background: #2d84fb;
    }
  [bg-color-theme="lightgreen"]{
    background: #67c56e;
    }
  [bg-color-theme="red"]{
    background: #dd2020;
    }
  [bg-color-theme="purple"]{
    background: #662e9b;
    }

/* Border Color Change */

  [border-color="orange"]{
  border: 2px solid #f89d36;
  }
  [border-color="lightblue"]{
  border: 2px solid #2d84fb;
  }
  [border-color="lightgreen"]{
  border: 2px solid #67c56e;
  }
  [border-color="red"]{
  border: 2px solid #dd2020;
  }
  [border-color="purple"]{
  border: 2px solid #662e9b;
  }

/* Color Change on Hover */

[hover-color="orange"]{
  border: 2px solid #f89d36;
  }
[hover-color="lightblue"]{
  border: 2px solid #2d84fb;
  }
[hover-color="lightgreen"]{
  border: 2px solid #67c56e;
  }
[hover-color="red"]{
  border: 2px solid #dd2020;
  }
[hover-color="purple"]{
  border: 2px solid #662e9b;
  }
/* --------------------------------------------------------- */

/* Project Button - Color Change */

[btn-hover-color="orange"]{
  border: 2px solid white;
  border-left: 2px solid  #f89d36;
  border-right: 2px solid  #f89d36;
  }
[btn-hover-color="lightblue"]{
  border: 2px solid white;
  border-left: 2px solid #2d84fb;
  border-right: 2px solid #2d84fb;
  }
[btn-hover-color="lightgreen"]{
  border: 2px solid white;
  border-left: 2px solid #67c56e;
  border-right: 2px solid #67c56e;
  }
[btn-hover-color="red"]{
  border: 2px solid white;
  border-left: 2px solid #dd2020;
  border-right: 2px solid #dd2020;
  }
[btn-hover-color="purple"]{
  border: 2px solid white;
  border-left: 2px solid #662e9b;
  border-right: 2px solid #662e9b;
  }

/* --------------------------------------------------------- */

/* SVG fill Color change */

[svg-color-change="orange"]{
  fill: #f89d36;
  }
  [svg-color-change="lightblue"]{
    fill: #2d84fb;
    }
  [svg-color-change="lightgreen"]{
    fill: #67c56e;
    }
  [svg-color-change="red"]{
    fill: #dd2020;
    }
  [svg-color-change="purple"]{
    fill: #662e9b;
    }

/* Music Equalizer */    

.music-equalizer-cont{
  position: fixed;
  right: 0;
  bottom: 0;
  cursor: pointer;
  height: 30px;
  background: none;
  z-index: 5;
  display: inline-block;
}

.music-eq{
  position: relative;
  width: 7px;
  background: coral;
  top: 100%;
  transform: translateY(-100%);
  float: left;
  margin-left: 2px;
  margin-right: 2px;
  background: linear-gradient(to top,#f89d36, #2d84fb, #67c56e, #dd2020, #662e9b);
  transition: 0.2s;
  /* cubic-bezier(.8, 0, .2, 1) */
}

/* #f89d36, #2d84fb, #67c56e, #dd2020, #662e9b */

/* Visitors Menu */

.visitors-menu-cont{
  position: fixed;
  width: 225px;
  height: 400px;
  background: white;  /* #f8f9fa */
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  right: -225px;
  border: 3px solid black;
  transition: 0.7s cubic-bezier(.8, 0, .2, 1);
}

.visitors-menu-cont:hover{
  right: 0;
}

.visitors-menu-cont .i-cont{
  position: absolute;
  width: 45px;
  height: 45px;
  left: -45px;
  top: -3px;
  border-top: 3px solid black;
  border-left: 3px solid black;
  border-bottom: 3px solid black;
  z-index: 400;
}

@keyframes inf-rotate {
  from {transform: translate(-50%, -50%) rotate(0deg) ;
  }
  to {transform: translate(-50%, -50%) rotate(359deg) ;
  }
}

.visitors-menu-cont .i-cont i{
  position: relative;
  font-size: 30px;
  left: 50%;
  top: 50%;
  background: transparent;
  animation: inf-rotate 2s infinite linear;

}

/* --------------------------------------------------------- */

/* Navbar Desktop and medium screen */

nav{
  background: transparent;
  height: 11vh;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5; /* it was 3 */
  transition: 0.7s cubic-bezier(.8, 0, .2, 1);
}

nav svg{
  position: relative;
  height: 50%;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  padding: 0;
  margin: 0;
  transition: 0.7s cubic-bezier(.8, 0, .2, 1);
}

nav ul{
  list-style-type: none;
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  background: none;
}

nav ul li{
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  background: none;
}

nav ul li a{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: black;
  transition: 500ms;
}

nav ul li:nth-child(1) a{
 /* color: coral;*/
}

nav ul li a:hover{
  text-decoration: none;
  /*color: #dd2020;*/
  transition: 300ms;
}

nav .social-media-cont{
  position: relative;
  display: inline-block;
  float: right;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10%;
  background: transparent;
}

nav .social-media-cont i{
  margin-left: 3px;
  margin-right: 3px;
  background: transparent;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid grey;
  transition: 500ms;
  cursor: pointer;
}

nav .social-media-cont i:hover{
  background: #dd2020;
  color: white;
  transition: 500ms;
  border: 1px solid #dd2020;
}

nav .social-media-cont i:nth-child(1){
  padding: 8px;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 18px;
}

nav .social-media-cont i:nth-child(4){
  padding: 8px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 18px;
}
/* --------------------------------------------------------- */

/* About Page - Page 1 */

.about-page-cont{
  position: relative;
  width: 100%;
  height: 100vh;
  background: transparent;
  overflow: hidden;
  background:  #f8f9fa;
}

.about-page-cont .cont{
  position: relative;
  background: transparent;
  width: 800px;
  height: auto;
  top: 40%;
  left: 20%;
  transform: translate(-20%, -40%);     /* Positioning needed later */
  z-index: 2;

}

.about-page-cont h1{
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 0;
  background: transparent;
}

.about-page-cont h2{
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 0;
  background: transparent;
}

.about-page-cont h2 span{
  background: transparent;
}

.about-page-cont p{
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.8em;
  margin-bottom: 30px;
  background: #f8f9fa;
  border-radius: 20px;
  padding: 15px;
}

.about-page-cont button{
  width: 170px;
  height: 40px;
  font-family: 'Poppins', sans-serif;
  background: transparent;
  border: 1px solid #dd2020;
  border-radius: 7px;
  transition: 500ms;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.about-page-cont button:hover{
  background: #dd2020;
  color: white;
  transition: 500ms;
}

.about-page-cont button i{
  margin-left: 7px;
  background: transparent;
}

.about-page-cont img{
  background: none;
  position: absolute;
  /* It was -150px and then -110px */
  top: -50px;
  left: -250px;
  margin: 0;
  padding: 0;
  min-width: 100%;
  
}
/* --------------------------------------------------------- */

/* Color Change Menu */

.color-menu-cont{
  position: fixed;
  width: 70px;
  height: auto;
  background: #f1f1f1;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  transition: 0.7s cubic-bezier(.8, 0, .2, 1);
}

.color-menu-cont:hover{
  left: 0px;
}

.color-menu-cont:hover .color-bar{
  box-shadow: 0 15px 25px rgba(0,0,80 ,.5 );
  transition: 0.3s ease-in;
}

.color-bar{
  position: absolute;
  width: 10px;
  height: 100%;
  right: 0;
  background: linear-gradient(180deg, #f89d36, #2d84fb, #67c56e, #dd2020, #662e9b);
  transition: 0.3s ease-out;
}

.color-menu-cont .coral{
  position: relative;
  width: 35px;
  height: 35px;
  background:  #f89d36;
  border-radius: 25px;
  left: 10px;
  margin-top: 10px;
}

.color-menu-cont .lightblue{
  position: relative;
  width: 35px;
  height: 35px;
  background:  #2d84fb;
  border-radius: 25px;
  left: 10px;
  margin-top: 10px;
}

.color-menu-cont .lightgreen{
  position: relative;
  width: 35px;
  height: 35px;
  background:  #67c56e;
  border-radius: 25px;
  left: 10px;
  margin-top: 10px;
}

.color-menu-cont .red{
  position: relative;
  width: 35px;
  height: 35px;
  background:   #dd2020;
  border-radius: 25px;
  left: 10px;
  margin-top: 10px;
}

.color-menu-cont .purple{
  position: relative;
  width: 35px;
  height: 35px;
  background:    #662e9b;
  border-radius: 25px;
  left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* --------------------------------------------------------- */

/* Projects Page - Page 2 */

.projects-page-cont{
  background:  #f8f9fa;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-bottom: 70px;
}

.projects-page-cont h1{
  margin: 0;
  margin-top: 80px;  /* MARGIN-TOP WILL CHANGE IN THE FUTURE */
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  background: none;
}

.projects-page-cont .projects-cont{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.projects-page-cont .projects-cont .project-1{
 
}
.project{
  background:  #f8f9fa;
  flex-grow: 1;
  width: 40%;
  height: auto;
  overflow: hidden;
  margin: 0;
}

.project .project-img-cont{
  position: relative;
  background: lightgreen;
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px;
  border: none;
  height: 100%;
  overflow: hidden;
}

.project .hidden-menu{
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 1;
  opacity: 0;
  transition: 0.4s;
}

.project .hidden-menu h1{
  position: relative;
  text-align: center;
  margin-top: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  background: none;
}

.project .hidden-menu p{
  position: relative;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
  font-size: medium;
  color: white;
  background: none;
  text-align: center;
}

.project .hidden-menu .btn-cont{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  background: transparent;
  margin-top: 50px;
}

.project .hidden-menu button{
  width: 150px;
  height: 50px;
  font-size: medium;
  font-family: 'Poppins', sans-serif;
  background: transparent;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  /*border-image: linear-gradient(to left, coral, #fff);*/
  /*border: 2px solid transparent;*/
  /*border-image-slice: 1;*/
  transition: 0.3s;
}

.project .hidden-menu button:hover{
  box-shadow: 0 15px 25px #fff;
  transition: 0.3s;
}

.project-img-cont:hover .hidden-menu{
  opacity: 0.8;
  transition: 0.4s;
}

.project-img-cont:hover .project-img{
  transform: scale(1.05);
  transition: 0.4s;
}

.project-2 .project-img-cont, .project-4 .project-img-cont, .project-6 .project-img-cont, .project-8 .project-img-cont{
  margin-top: 200px;
}

.project-1 .project-img-cont{
  margin-top: 0;
}

.project img{
  position: relative;
  width: 100%;
  border-radius: 25px;
  height: 100%;
  transition: 0.5s;
  /*transform: scale(1.5);*/
}

.project-4{

}

/* --------------------------------------------------------- */

/* Services Page - Page 3 */

.services-page-cont{
 position: relative;
 width: 100%;
 /*background: #f8f9fa;*/
 padding-bottom: 80px;
}

.services-page-cont h1{
  font-family: 'Poppins', sans-serif;
  color: black;
  text-align: center;
  margin-bottom: 20px;
  background: none;
}

.barD{ /* .services-page-cont  */
   position: relative;
   width: 120px;
   height: 3px;
   left: 50%;
   transform: translateX(-50%);
   margin-bottom: 20px;
}

.divider{ /* .services-page-cont .services-bar */
  position: absolute;
  width: 11px;
  height: 11px;
  left: 55px;
  transform: translateX(-75px);
  top: -4px;
  transform: rotate(45deg);
}

.services-page-cont p{
  position: relative;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  width: 600px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  margin-bottom: 20px;
}

.service{
  position: relative;
  top: 0;
  transition: 0.3s ease-out;
}

.service .bar{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30%;
  height: 3px;
  opacity: 0;
  transition: 0.3s ease-in;
}

.service:hover .bar{
  transition: 0.3s ease-out;
  opacity: 1;
  width: 100%;
}

.service:hover{
  position: relative;
  top: -5px;
  transition: 0.3s ease-in;
}

.services-page-cont .services-cont .service-cont{
  position: relative;
  background: white;
  transition: 0.3s ease-out;
}

.services-page-cont .services-cont .service-cont i{
  position: relative;
  font-size: 30px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  margin-bottom: 20px;
  background: none;
}

.services-page-cont .services-cont .service-cont h1{
  margin-bottom: 20px;
  background: none;
  font-size: 20px;
}

.s1-cont:hover #s1, .s2-cont:hover #s2, .s3-cont:hover #s3, .s4-cont:hover #s4, .s5-cont:hover #s5, .s6-cont:hover #s6{
  color: #dd2020;
}

.services-page-cont .services-cont .service-cont p{
  width: 100%;
  padding-bottom: 40px;
  background: none;
}

/* --------------------------------------------------------- */

/* Lets Talk Section - Between Page 3 and Page 4 */

.talk-section-cont{
  padding-top: 40px;
  padding-bottom: 40px;
}

.talk-section-cont .row{
  background: url('./images/laptop-coffee-cup-agenda.jpg');
  min-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.talk-section-cont .title-cont{
  background: transparent;
  height: 40vh;
}

.talk-section-cont h1{
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  width: 90%;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
  margin: 0;
}

.title-btn-cont{
  height: 40vh;
  background: transparent;
}

.talk-section-cont button{
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  font-size: 20px;
  width: 200px;
  height: 50px;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  border: none;
  transition: 0.5s ease-in-out;
}

.talk-section-cont button:hover .block-cont{
  left: -10%;
}

.talk-section-cont button:hover{
  color: #fff;
  transition: 0.5s ease-in-out;
}

.block-cont{
  position: absolute;
  width: 200%;
  height: 100%;
  top: 0;
  left: -90%;
  z-index: -1;
  transition: 0.5s ease-in-out;
}

.talk-section-cont button .block-1{
  position: relative;
  width: 50%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  float: left;
}

.talk-section-cont button .block-2{
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  z-index: -1;
  top: 0;
  left: 0;
  float: left;
}

/* --------------------------------------------------------- */

/* Contact Page - Page 4 */

.contact-page-cont{
  padding-top: 70px;
}

.contact-page-cont h1{
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  margin-bottom: 20px;
}

.contact-page-cont .row{
  padding-top: 20px;
  padding-bottom: 40px;
}

.contact i{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-size: 25px;
  padding-top: 20px;
  color: #000;
}

.contact h4{
  position: relative;
  text-align: center;
  margin-top: 30px;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  color: #000;
}

.contact h5{
  text-align: center;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.contact p{
  position: relative;
  text-align: center;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 20px;
}

form input{
  width: 100%;
  height: 40px;
  border: 1px solid grey;
  font-size: 20px;
  padding-left: 10px;
  font-family: 'Poppins', sans-serif;
  border-radius: 7px;
}

form input:nth-child(1), form input:nth-child(2){
  margin-bottom: 30px;
}

small{
  padding-left: 10px;
  color: #000;
  font-weight: bold;
}

form textarea{
  width: 100%;
  margin-bottom: 20px;
  height: 180px;
  border: 1px solid grey;
  font-size: 20px;
  padding-left: 10px;
  font-family: 'Poppins', sans-serif;
  padding-top: 5px;
  border-radius: 7px;
}

form button{
  position: relative;
  float: right;
  width: 170px;
  height: 40px;
  font-size: 17px;
  border: none;
  letter-spacing: 0.5px;
  color: #fff;
  border-radius: 7px;
  transition: 500ms;
}

form button:hover{
  background: #000;
  color: #fff;
}

/* --------------------------------------------------------- */

/* Contact Page - Page 4 */

footer{
position: relative;
background: #adb5bd;
padding-top: 30px;
padding-bottom: 15px;
margin-top: 70px;
}

footer svg{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  background: none;
}

hr{
  height: 1px;
        background-color: #ccc;
        border: none;
}

footer p{
  text-align: center;
  background: none;
  color: #000;
  font-family: 'Poppins', sans-serif;
}

footer i{
  background: none;
  color: rgb(151, 1, 1);
}

/* --------------------------------------------------------- */

/* Media Query */

@media only screen and (max-width: 990px){

nav .social-media-cont{
  display: none;
}

.project{
  width: 90%;
}

.projects-page-cont .projects-cont{
  flex-direction: column;
}

.project .project-img-cont{
  width: 95%;
}

.project-1 .project-img-cont{
  margin-top: 50px;
}

.project .project-img-cont{
    margin-top: 50px;
  }

  .about-page-cont .cont{
    width: 100%;
  }

  .about-page-cont h1, .about-page-cont h2{
    font-size: 50px;
    margin-left: 40px;
  }
  .about-page-cont p{
    position: relative;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
  .about-page-cont button{
    margin-left: 40px;
  }
}

@media only screen and (max-width: 767px){

  .project{
    width: 100%;
  }
  
  .projects-page-cont .projects-cont{
    flex-direction: column;
  }

  .project .project-img-cont{
    width: 95%;
  }

  .project-2 .project-img-cont, .project-4 .project-img-cont{
    margin-top: 50px;
  }

  .services-page-cont p{
    width: 90%;
  }

  .talk-section-cont .title-cont{
    height: 20vh;
  }

  .title-btn-cont{
    height: 20vh;
  }

  .about-page-cont .cont{
    width: 100%;
    margin-bottom: 800px;
  }

  .about-page-cont h1{
    margin-top: 60px;
  }

  .about-page-cont h1, .about-page-cont h2{
    font-size: 20px;
    margin-left: 30px;
    margin-bottom: 0px;
  }
  .about-page-cont p{
    position: relative;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0px;
    margin-top: 5px;
  }
  .about-page-cont button{
    margin-left: 30px;

  }
}